<template>
  <div class="box">

    <div class="mb-4 relative">
      <!--  -->
      <div class="accordion-item col-span-12 text-xs">
        <div id="faq-accordion-content" class="border-b border-gray-200 dark:border-dark-5 accordion-header flex justify-between col-span-2">
          <button
            class="accordion-button text-sm font-medium focus:outline-none text-primary-1 dark:text-white"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#faq-accordion-collapse-1"
            aria-expanded="true"
            aria-controls="faq-accordion-collapse-1"
          >
            <div class="flex flex-col sm:flex-row items-center p-4">
              <h4 class="mr-auto capitalize text-base font-medium font-bold">Auditoria</h4>
            </div>
          </button>
        </div>
        <div id="faq-accordion-collapse" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content" data-bs-parent="#faq-accordion">
          <div class="accordion-body text-gray-700 m-4 dark:text-gray-600 leading-relaxed">

            <!-- Filtros -->
            <section class="w-full pb-4">
              <div class="grid grid-cols-7 gap-x-6 gap-y-2">
                <div class="col-span-7 sm:col-span-2">
                  <label for="fechaReportada" class="block text-sm font-medium text-gray-700">Periodo Rad:</label>
                  <select
                    v-model="filters.fechaReportada"
                    class="form-select form-select-sm mt-1"
                    name="fechaReportada"
                    id="fechaReportada"
                  >
                    <option
                      v-for="(value, k) in listFechasReportadas"
                      :key="k"
                      :value="value"
                    >
                      {{value}}
                    </option>
                  </select>
                </div>
                <!-- <div class="col-span-7 sm:col-span-1">
                  <label for="porcentaje" class="block text-sm font-medium text-gray-700">Porcentaje:</label>
                  <span class="p-input-icon-right">
                    <i class="pi pi-percentage text-sm" />
                    <InputNumber
                      id="porcentaje"
                      v-model="filters.porcentaje"
                      class="p-inputtext-xs mt-1"
                      style="height: 30px;"
                      :min="0"
                      :max="100"
                    />
                  </span>
                </div> -->
                <div class="col-span-7 sm:col-span-1 flex items-end justify-start">
                  <Button
                    label="Buscar"
                    icon="pi pi-search"
                    class="p-button-xs"
                    @click="handleSearch"
                  />
                </div>
              </div>
            </section>

            <div v-if="cargando" style="width:100%; height: 300px" class="rounded-md icono-carga flex justify-center items-center relative">
              <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" style="width:100%; height: 300px"/>
            </div>

            <div class="col-span-12 lg:col-span-12 pb-4" v-else>

              <!-- Totales, solo visibles en mobile -->
              <div class="flex md:flex-row md:hidden bg-green-50 flex-col justify-between">
                <div class="flex justify-between items-stretch flex-wrap text-[#1004A6] m-2">
                  <div class="flex justify-between sm:justify-center items-center sm:mr-5 mr-0 w-full sm:w-auto">
                    <h3 class="font-bold ">Total Vlr. Facturado:</h3>
                    <span class=" ml-2">{{ totalVlrFacturas }}</span>
                  </div>
                  <div class="flex justify-between sm:justify-center items-center sm:mr-5 mr-0 w-full sm:w-auto">
                    <h3 class="font-bold ">Total Vlr. Facturado:</h3>
                    <span class=" ml-2">{{ totalVlrSaldo }}</span>
                  </div>
                  <div class="flex justify-between sm:justify-center items-center sm:mr-5 mr-0 w-full sm:w-auto">
                    <h3 class="font-bold ">Total Vlr. Glosado:</h3>
                    <span class=" ml-2">{{ totalVlrGlosas }}</span>
                  </div>
                  <div class="flex justify-between sm:justify-center items-center sm:mr-5 mr-0 w-full sm:w-auto">
                    <h3 class="font-bold ">Total Vlr. Aceptado:</h3>
                    <span class=" ml-2">{{ totalVlrAceptadas }}</span>
                  </div>
                </div>
              </div>

              <!-- Totales, solo visibles en mobile -->
              <!-- <DataTable
                v-if="totalsMobile"
                :value="totalsTable"
                responsiveLayout="stack"
                breakpoint="768px"
                class="p-datatable-sm text-xs pb-4"
                showGridlines
              >
                <template #header>
                  <div class="flex justify-between">
                    <span class="text-lg">Totales</span>
                  </div>
                </template>
                <Column>
                  <template #body="{data}">
                    {{ data.value }}
                  </template>
                </Column>
              </DataTable> -->

              <DataTable
                :value="dataAuditoria"
                :paginator="true"
                :rows="25"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[5,10,25,50,100]"
                currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
                responsiveLayout="stack"
                breakpoint="768px"
                showGridlines
                class="p-datatable-sm text-right text-xs mt-2"
                v-model:expandedRows="expandedRows"
                @rowExpand="onRowExpand"
                @rowCollapse="onRowCollapse"
              >
                <ColumnGroup type="header">
                  <Row>
                    <Column header="" style="width: 55px; height: 10px"  />
                    <Column header="Proveedor" style="font-weight: bold" field="nombreCorto" headerStyle="width:20%"/>
                    <Column header="Fecha Reportada" style="font-weight: bold" field="fechaReportada"/>
                    <Column header="Vlr. Factura" style="font-weight: bold" field="valorFactura"/>
                    <Column header="Vlr. Saldo" style="font-weight: bold" field="valorSaldo"/>
                    <Column header="Vlr. Glosas" style="font-weight: bold" field="valorGlosas"/>
                    <Column header="% Glosado" style="font-weight: bold" field="percentGlosado"/>
                    <Column header="Vlr. Aceptado" style="font-weight: bold" field="valorAceptado"/>
                    <Column header="% Aceptado" style="font-weight: bold" field="percentAceptado"/>
                    <Column header="Vlr. Auditado" style="font-weight: bold" field="valorAuditado"/>
                    <!-- <Column header="Vlr. Porcentaje" style="font-weight: bold" field="valorPorcentaje"/> -->
                  </Row>
                  <Row>
                    <Column header="Totales:" :colspan="1" footerStyle="text-align:right"/>
                    <Column header="" />
                    <Column header="" />
                    <Column :header="totalVlrFacturas" />
                    <Column :header="totalVlrSaldo" />
                    <Column :header="$h.formatCurrency(totalVlrGlosas)" />
                    <Column header="" />
                    <Column :header="$h.formatCurrency(totalVlrAceptadas)" />
                    <Column header="" />
                    <Column :header="$h.formatCurrency(totalVlrGlosas + totalVlrAceptadas)" />
                    <!-- <Column :header="totalVlrPorcentaje" /> -->
                  </Row>
                </ColumnGroup>
                <Column :expander="true" style="width: 25px; height: 10px; text-align: center"  />
                <Column header="Proveedor" field="nombreCorto"></Column>
                <Column header="Fecha Reportada" field="fechaReportada"></Column>
                <Column header="Vlr. Factura" field="valorFactura">
                  <template #body="{data}">
                    <span>{{ $h.formatCurrency(data.valorFactura, 0) }}</span>
                  </template>
                </Column>
                <Column header="Vlr. Saldo" field="valorSaldo">
                  <template #body="{data}">
                    <span>{{ $h.formatCurrency(data.valorSaldo, 0) }}</span>
                  </template>
                </Column>
                <Column header="Vlr. Glosas" field="valorGlosas">
                  <template #body="{data}">
                    <span>{{ $h.formatCurrency(data.valorGlosas, 0) }}</span>
                  </template>
                </Column>
                <Column header="% Glosado" field="percentGlosado">
                  <template #body="{data}">
                    <span>{{ data.percentGlosado }}%</span>
                  </template>
                </Column>
                <Column header="Vlr. Aceptado" field="valorAceptado">
                  <template #body="{data}">
                    <span>{{ $h.formatCurrency(data.valorAceptado, 0) }}</span>
                  </template>
                </Column>
                <Column header="% Aceptado" field="percentAceptado">
                  <template #body="{data}">
                    <span>{{ data.percentAceptado }}%</span>
                  </template>
                </Column>

                <Column header="Vlr. Auditado" field="valorAuditado">
                  <template #body="{data}">
                    <span>{{ $h.formatCurrency(data.valorGlosas + data.valorAceptado, 0) }}</span>
                  </template>
                </Column>
                <!-- <Column header="Vlr. Porcentaje" field="valorPorcentaje">
                  <template #body="{data}">
                    <span>{{ $h.formatCurrency(data.valorPorcentaje, 0) }}</span>
                  </template>
                </Column> -->

                <!-- Detalle -->
                <template #expansion="{data}">
                  <!-- <div v-if="cargandoDetalle" style="width:100%; height: 300px" class="rounded-md icono-carga flex justify-center items-center relative">
                    <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" style="width:100%; height: 300px"/>
                  </div> -->
                  <div class="w-full px-2 pb-2 text-xs bg-gray-200">

                    <section class="w-full pb-2 pt-1 text-xs bg-gray-200">
                      <div class="grid grid-cols-12 gap-x-4 gap-y-2">
                        <div class="col-span-12 sm:col-span-7">
                          <p class="py-2">
                            <span class="font-normal">Detalle Auditoria del Proveedor: </span>
                            <span class="font-semibold">{{ data.nombreCorto }}</span>
                          </p>
                        </div>
                        <div class="col-span-12 sm:col-span-4">
                          <span class="p-input-icon-right w-full">
                            <i class="pi pi-search text-sm" />
                            <InputText
                              id="facturaNumero"
                              v-model="filters.facturaNumero"
                              class="p-inputtext-sm mt-1 w-full"
                              style="height: 30px;"
                              placeholder="Buscar por numero de factura..."
                            />
                          </span>
                        </div>
                        <div class="col-span-12 sm:col-span-1 flex items-end justify-start">
                          <Button
                            label="Buscar"
                            icon="pi pi-search"
                            class="p-button-xs"
                            @click="onFilter(data)"
                          />
                        </div>
                      </div>
                    </section>

                    <DataTable
                      :value="detailsAuditoria"
                      responsiveLayout="stack"
                      breakpoint="960px"
                      :loading="loading"
                      class="p-datatable-sm text-xs"
                      :lazy="true"
                      :paginator="true"
                      :rows="10"
                      :totalRecords="totalRecords"
                      @page="onPage($event, data)"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      :rowsPerPageOptions="[5,10,25,50,100]"
                      currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
                    >
                      <Column field="facturaNumero" header="Número Factura"/>
                      <Column field="valorFactura" header="Vlr. Factura">
                        <template #body="{data}">
                          {{ $h.formatCurrency(data.valorFactura, 0) }}
                        </template>
                      </Column>
                      <Column field="valorSaldo" header="Vlr. Saldo">
                        <template #body="{data}">
                          {{ $h.formatCurrency(data.valorSaldo, 0) }}
                        </template>
                      </Column>
                      <Column field="valorGlosas" header="Vlr. Glosas" :sortable="false" style="min-width:8rem; text-align:right;">
                        <template #body="{data}">
                          {{ $h.formatCurrency(data.valorGlosas, 0) }}
                        </template>
                      </Column>
                      <Column header="% Glosado" field="percentGlosado">
                        <template #body="{data}">
                          <span>{{ data.percentGlosado }}%</span>
                        </template>
                      </Column>
                      <Column field="valorAceptado" header="Vlr. Aceptado" :sortable="false" style="min-width:8rem; text-align:right;">
                        <template #body="{data}">
                          {{ $h.formatCurrency(data.valorAceptado, 0) }}
                        </template>
                      </Column>
                      <Column header="% Aceptado" field="percentAceptado">
                        <template #body="{data}">
                          <span>{{ data.percentAceptado }}%</span>
                        </template>
                      </Column>
                      <Column header="Vlr. Auditado" field="valorAuditado">
                        <template #body="{data}">
                          <span> {{ $h.formatCurrency(data.valorAceptado + data.valorGlosas, 0) }}</span>
                        </template>
                      </Column>
                      <!-- <Column field="valorPorcentaje" header="Vlr. Porcentaje" :sortable="false" style="min-width:8rem; text-align:right;">
                        <template #body="{data}">
                          {{ $h.formatCurrency(data.valorPorcentaje, 0) }}
                        </template>
                      </Column> -->
                    </DataTable>
                  </div>
                </template>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import { reactive, ref, onMounted, computed } from 'vue'
import { apiListAuditoria, apiDetailsAuditoria } from './services'
import currency from 'currency.js'
import dayjs from 'dayjs'
import unique from 'lodash/uniq'

export default {
  name: 'cardAuditoria',
  props: {
    typeCompany: {
      type: Number,
      default: 1
    }
  },
  components: {
  },
  setup (props) {
    const expandedRows = ref([])
    const filters = reactive({
      facturaNumero: '',
      // fechaReportada: dayjs().subtract(1, 'month').format('YYYY-MM'),
      fechaReportada: dayjs().format('YYYY-MM'),
      porcentaje: 20,
      typeCompany: props.typeCompany
    })
    const dataAuditoria = ref([])
    const cargando = ref(false)
    const cargandoDetalle = ref(false)
    const detailsAuditoria = ref([])
    const listFechasReportadas = ref([])
    const totalsMobile = ref(false)
    const totalRecords = ref(0)
    const lazyParams = ref({})
    const loading = ref(true)
    const valorMain = ref(0)
    const valorDetail = ref(0)

    /* Realizar busqueda */
    const handleSearch = () => {
      fetchListAuditoria(filters)
    }

    /* Obtener los datos de la auditoria */
    const fetchListAuditoria = (filters) => {
      cargando.value = true
      return apiListAuditoria(filters).then(({ status, data: response }) => {
        dataAuditoria.value = response.infoAuditoria.map((item) => {
          if (props.typeCompany === 1) {
            valorMain.value = item.valorFactura
          } else {
            valorMain.value = item.valorSaldo
          }
          return {
            ...item,
            percentGlosado: ((item.valorGlosas / valorMain.value) * 100).toFixed(0),
            percentAceptado: ((item.valorAceptado / valorMain.value) * 100).toFixed(0)
          }
        })
        cargando.value = false
      }).catch(err => {
        cargando.value = false
        console.error(err.message)
      })
    }

    /* Total Valor Facturas */
    const totalVlrFacturas = computed(() => {
      let total = 0
      for (const pres of dataAuditoria.value) {
        total += pres.valorFactura ? pres.valorFactura : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })

    /* Total Valor Saldo */
    const totalVlrSaldo = computed(() => {
      let total = 0
      for (const pres of dataAuditoria.value) {
        total += pres.valorSaldo ? pres.valorSaldo : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })

    /* Total Valor Glosados */
    const totalVlrGlosas = computed(() => {
      let total = 0
      for (const pres of dataAuditoria.value) {
        total += pres.valorGlosas ? pres.valorGlosas : 0
      }

      return total // `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })

    /* Total Valor Aceptados */
    const totalVlrAceptadas = computed(() => {
      let total = 0
      for (const pres of dataAuditoria.value) {
        total += pres.valorAceptado ? pres.valorAceptado : 0
      }

      return total // `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })

    /* Total Valor Porcentaje */
    const totalVlrPorcentaje = computed(() => {
      let total = 0
      for (const pres of dataAuditoria.value) {
        total += pres.valorPorcentaje ? pres.valorPorcentaje : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })

    const totalsTable = ref([
      { label: 'Total Facturas', value: totalVlrFacturas },
      { label: 'Total Saldo', value: totalVlrSaldo },
      { label: 'Total Glosados', value: totalVlrGlosas },
      { label: 'Total Aceptados', value: totalVlrAceptadas },
      { label: 'Total Aceptados', value: totalVlrPorcentaje }
    ])

    /**/
    const onPage = (event, data) => {
      lazyParams.value = event
      fetchDetailsAuditoria(data, filters.porcentaje, filters.typeCompany, filters.facturaNumero, event)
    }

    /**/
    const onFilter = (data) => {
      fetchDetailsAuditoria(data, filters.porcentaje, filters.typeCompany, filters.facturaNumero)
    }

    /* Obtener los datos detallados de la auditoria */
    const fetchDetailsAuditoria = ({ id, fechaReportada }, porcentaje, typeCompany, facturaNumero, event = false) => {
      let queryParams = {}
      if (!event) {
        queryParams = {
          current_page: 1,
          per_page: 10,
          id,
          fechaReportada,
          porcentaje,
          typeCompany,
          facturaNumero
        }
      } else {
        queryParams = {
          current_page: event.page + 1,
          per_page: event.rows,
          id,
          fechaReportada,
          porcentaje,
          typeCompany,
          facturaNumero
        }
      }

      loading.value = true
      return apiDetailsAuditoria(queryParams).then(({ status, data: response }) => {
        detailsAuditoria.value = response.data.map((item) => {
          if (props.typeCompany === 1) {
            valorDetail.value = item.valorFactura
          } else {
            valorDetail.value = item.valorSaldo ? item.valorSaldo : 1
          }
          return {
            ...item,
            percentGlosado: ((item.valorGlosas / valorDetail.value) * 100).toFixed(0),
            percentAceptado: ((item.valorAceptado / valorDetail.value) * 100).toFixed(0)
          }
        })
        totalRecords.value = response.total
        loading.value = false
      }).catch(err => {
        loading.value = false
        console.error(err.message)
      })
    }

    const onRowExpand = ({ data }) => {
      fetchDetailsAuditoria(data, filters.porcentaje, filters.typeCompany, filters.facturaNumero)
      expandedRows.value = [data]
    }
    const onRowCollapse = () => {
      expandedRows.value = []
    }

    /* Obtener fecha */
    const getFechas = () => {
      const fechaInicio = new Date('2021-12')
      const fechaFin = new Date()
      const arrayFechas = []

      while (fechaFin.getTime() >= fechaInicio.getTime()) {
        fechaInicio.setDate(fechaInicio.getDate() + 1)

        arrayFechas.push(dayjs(fechaInicio).format('YYYY-MM'))
      }

      listFechasReportadas.value = unique(arrayFechas)
    }

    const loadEvents = () => {
      window.addEventListener('resize', (evt) => {
        totalsMobile.value = (window.innerWidth < 768)
      })
    }

    onMounted(() => {
      totalsMobile.value = (window.innerWidth < 768)
      getFechas()
      loadEvents()
      fetchListAuditoria(filters)
    })

    return {
      dataAuditoria,
      filters,
      cargando,
      cargandoDetalle,
      handleSearch,
      onRowExpand,
      onRowCollapse,
      expandedRows,
      detailsAuditoria,
      totalVlrFacturas,
      totalVlrSaldo,
      totalVlrGlosas,
      totalVlrAceptadas,
      totalVlrPorcentaje,
      listFechasReportadas,
      totalsMobile,
      totalsTable,
      onPage,
      onFilter,
      totalRecords,
      loading
    }
  }
}
</script>

<style>
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler {
  width: 1rem !important;
  height: 1rem !important;
}
button > .p-row-toggler-icon {
  font-size: 0.75rem;
}
</style>

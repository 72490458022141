import http from '../../../../../../../libs/http'

const api = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD_UTESPECIALIZADA}`

const apiListAuditoria = (query) => http.get(`${api}/dashboard/auditoria`, { params: { ...query } })
const apiDetailsAuditoria = (query) => http.get(`${api}/dashboard/details-auditoria`, { params: { ...query } })

export {
  apiListAuditoria,
  apiDetailsAuditoria
}
